import * as React from "react";
import { graphql } from "gatsby"

import BrewlogArticle from '../../components/BrewlogArticle';

export default function Brewlog({ data }) {
  return (
    <BrewlogArticle doc={data.allMdx.nodes[0]} />
  );
}

export const query = graphql`
query {
  allMdx(sort: { fields: frontmatter___date }, limit: 1) {
    nodes {
      ...brewlogData
    }
  }
}`;